@media (min-width: 768px) {
  .five-cols .col-md-2,
  .five-cols .col-sm-2,
  .five-cols .col-lg-2 {
    width: 20%;
    *width: 20%;
  }
}

@media (min-width: 992px) {
  .five-cols .col-md-2,
  .five-cols .col-sm-2,
  .five-cols .col-lg-2 {
    width: 20%;
    *width: 20%;
  }
}

@media (min-width: 1200px) {
  .five-cols .col-md-2,
  .five-cols .col-sm-2,
  .five-cols .col-lg-2 {
    width: 20%;
    *width: 20%;
  }
}

.five-cols .col-md-2,
.five-cols .col-sm-2,
.five-cols .col-lg-2 {
  width: 20%;
  *width: 20%;
}

.fontbold {
  font-weight: bold;
}
