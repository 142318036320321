.swiper-container {
  width: 100%;
  max-height: 600px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  max-height: 600px;

  background-size: 100% 100%;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  max-height: 600px;
}

#root .banner .swiper-button-prev,
#root .banner .swiper-button-next {
  color: rgb(37, 44, 68) !important;
}

.swiper-button-prev {
  /* color: rgb(209, 209, 209) !important; */
  color: rgb(37, 44, 68);
}

.swiper-button-next {
  /* color: rgb(209, 209, 209) !important; */
  color: rgb(37, 44, 68);
}
/*  */
.contenedor {
  position: relative;
  display: inline-block;
}

.texto-encima {
  color: white;
  background: rgba(45, 80, 200);
  background: linear-gradient(
    90deg,
    rgba(45, 80, 200, 0) 0%,
    rgba(45, 80, 200, 0.3) 4%,
    rgba(45, 80, 200, 0.6) 50%,
    rgba(45, 80, 200, 0.3) 96%,
    rgba(45, 80, 200, 0)
  );
}
