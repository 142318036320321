.mySwiper {
  max-width: 1500px;
}

/* .carrousel-container {
  max-width: 1500px;
} */

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  min-height: 200px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
