.cardBorder {
  width: 90%;
  height: 300px;
}

.card_style {
  box-shadow: 10px 10px 25px rgba(180, 180, 150, 0.5);
  border-radius: 10px;
  background-color: white;
  height: auto;
}
