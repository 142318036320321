.imagenMuestra {
  /* -webkit-transform: skewX(-5deg);
  transform: skewX(-5deg); */
  box-shadow: 5px 5px 5px black;
  transform: rotate3d(0, 10, 60, 5deg);
  border-radius: 10px;
}

.imgShadow {
  box-shadow: 5px 5px #888888;
}

.product-card {
  width: 25rem;
}
