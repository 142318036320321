.swiper-container {
  width: 100%;
  max-height: 600px;
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
}

.swiper-container-margin {
  margin-top: 80px;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  max-height: 600px;

  background-size: 100% 100%;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  max-height: 600px;
}

.backColor {
  background-color: rgb(235, 235, 235);
}

.icon_size {
  font-size: 50px;
}

.card_content {
  display: table;
}

#home .companies .image-1 {
  width: 100%;
}

#home .companies .image-2 {
  width: 100%;
}

#home .companies .swiper-container {
  box-shadow: none;
}

#home .companies .swiper-slide {
  background-color: transparent;
}

@media screen and (max-width: 960px) {
  .swiper-container-margin {
    margin-top: 0px;
  }
}
