.NavbarItems {
  height: 80px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  position: fixed;
  z-index: 20;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  margin-bottom: 0;
}
.nav-menu-social {
  color: #fff;
  list-style: none;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(5, auto);
  padding-left: 0;
}
.nav-menu-social-item {
  cursor: pointer;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu-social-item > * {
  color: white;
}

.nav-menu-social-item > *:hover {
  color: rgb(204, 204, 204);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 74vw;
  justify-content: end;
  /* margin-right: 2rem; */
  margin-bottom: 0;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links:hover {
  background-color: #007bff;
  height: 80px;
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.logo {
  width: 150px;
  height: 100px;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 18.5rem;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin: 0px;
    padding-left: 0;
  }

  .nav-menu.active {
    background: #2f7ef3;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
    margin-bottom: 0;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    margin-bottom: 0;
  }

  .nav-links:hover {
    background-color: #0060ee;
    color: white;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    color: white;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #4ad9e4;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #6568f4;
    transition: 250ms;
  }

  Button {
    display: none;
  }
  .nav-menu-social {
    margin-bottom: 10px;
  }
  .logo {
    width: 110px;
    height: 100px;
    margin-left: 10px;
  }
}
