.logoFooter {
  width: 150px;
  height: 100px;
}
.backcolorFooter {
  background-color: #ebebeb;
}

.footer_link a {
  color: #898989;
  text-decoration: none;
  cursor: pointer;
}

.footer_link a:hover {
  color: #0a58ca !important;
}

.vinietas > li {
  color: rgb(150, 149, 149);
}

.vinietas > li > span {
  color: #898989;
  text-decoration: none;
}

.btn-flotante {
  font-size: 26px; /* Cambiar el tamaño de la tipografia */
  text-transform: uppercase; /* Texto en mayusculas */
  font-weight: bold; /* Fuente en negrita o bold */
  color: #ffffff; /* Color del texto */
  border-radius: 10px; /* Borde del boton */
  cursor: pointer;
  /* padding: 16px 23px; Relleno del boton */
  position: fixed;
  bottom: 40px;
  right: 10px;
  transition: all 300ms ease 0ms;
  z-index: 99;
  /* border-radius: 50px; */
  width: 5rem;
}

.btn-flotante:hover {
  color: #0d6efd;
  transform: translateY(-7px);
}

@media (min-width: 992px) {
  .btn-flotante {
    bottom: 40px;
    right: 40px;
  }
}

@media (min-width: 1200px) {
  .btn-flotante {
    font-size: 14px;
    padding: 12px 20px;
    bottom: 20px;
    width: 20rem;
  }
}

.linksFooter {
  color: #898989;
  text-decoration: none;
}
