.listaMuroMovil li {
  margin: 25px 0;
}

.listaMuroMovil {
  margin-top: auto;
  margin-bottom: auto;
}

.textoMuroMovil {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .muroImagenCentrada {
    display: block;
    margin: 0 auto;
  }
}
