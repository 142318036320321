.listado {
  list-style: none;
  padding-left: 0;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 2rem;
}

.listado li {
  padding: 10px 0;
}

.listado li:hover {
  background-color: #0060ee;
  border-radius: 10px;
}

.imagenListado {
  width: 40%;
}

.listado .active {
  background-color: #0060ee;
  border-radius: 10px;
}
