* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

body #root {
  color: #898989;
}

body #root h1,
body #root h2,
body #root h3,
body #root h4 {
  color: #393939;
}

/* COLORS */
#root .bg-primary,
#root .btn-primary {
  background-color: #0060c4 !important;
}

#root .text-primary {
  color: #2d89c8 !important;
}

#root .nav-links:hover,
#root .btn-primary:hover {
  background-color: #0671e0 !important;
}

/* SHADOW */
.buttonShadow {
  box-shadow: 0px 1px 1px 1px rgba(118, 118, 109, 0.6);
}

/* SWIPER */
#root .swiper-button-prev,
#root .swiper-button-next {
  /* color: #fafafa !important; */
  color: white !important;
}

#root .clients .swiper-button-prev,
#root .clients .swiper-button-next {
  color: #0671e0 !important;
}

#root .swiper-pagination-bullet-active {
  background: #fafafa !important;
}

#root .clients .swiper-pagination-bullet-active {
  background: #0671e0 !important;
}

#root .mySwiper {
  box-shadow: none;
}

@media (min-width: 961px) {
  .marginTop {
    margin-top: 80px;
  }
}
