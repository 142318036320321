body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NavbarItems {
  height: 80px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  position: fixed;
  z-index: 20;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  margin-bottom: 0;
}
.nav-menu-social {
  color: #fff;
  list-style: none;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(5, auto);
  padding-left: 0;
}
.nav-menu-social-item {
  cursor: pointer;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu-social-item > * {
  color: white;
}

.nav-menu-social-item > *:hover {
  color: rgb(204, 204, 204);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 74vw;
  justify-content: end;
  /* margin-right: 2rem; */
  margin-bottom: 0;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links:hover {
  background-color: #007bff;
  height: 80px;
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.logo {
  width: 150px;
  height: 100px;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 18.5rem;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin: 0px;
    padding-left: 0;
  }

  .nav-menu.active {
    background: #2f7ef3;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10;
    margin-bottom: 0;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    margin-bottom: 0;
  }

  .nav-links:hover {
    background-color: #0060ee;
    color: white;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    color: white;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #4ad9e4;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #6568f4;
    transition: 250ms;
  }

  Button {
    display: none;
  }
  .nav-menu-social {
    margin-bottom: 10px;
  }
  .logo {
    width: 110px;
    height: 100px;
    margin-left: 10px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

body #root {
  color: #898989;
}

body #root h1,
body #root h2,
body #root h3,
body #root h4 {
  color: #393939;
}

/* COLORS */
#root .bg-primary,
#root .btn-primary {
  background-color: #0060c4 !important;
}

#root .text-primary {
  color: #2d89c8 !important;
}

#root .nav-links:hover,
#root .btn-primary:hover {
  background-color: #0671e0 !important;
}

/* SHADOW */
.buttonShadow {
  box-shadow: 0px 1px 1px 1px rgba(118, 118, 109, 0.6);
}

/* SWIPER */
#root .swiper-button-prev,
#root .swiper-button-next {
  /* color: #fafafa !important; */
  color: white !important;
}

#root .clients .swiper-button-prev,
#root .clients .swiper-button-next {
  color: #0671e0 !important;
}

#root .swiper-pagination-bullet-active {
  background: #fafafa !important;
}

#root .clients .swiper-pagination-bullet-active {
  background: #0671e0 !important;
}

#root .mySwiper {
  box-shadow: none;
}

@media (min-width: 961px) {
  .marginTop {
    margin-top: 80px;
  }
}

.swiper-container {
  width: 100%;
  max-height: 600px;
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
}

.swiper-container-margin {
  margin-top: 80px;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  max-height: 600px;

  background-size: 100% 100%;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  max-height: 600px;
}

.backColor {
  background-color: rgb(235, 235, 235);
}

.icon_size {
  font-size: 50px;
}

.card_content {
  display: table;
}

#home .companies .image-1 {
  width: 100%;
}

#home .companies .image-2 {
  width: 100%;
}

#home .companies .swiper-container {
  box-shadow: none;
}

#home .companies .swiper-slide {
  background-color: transparent;
}

@media screen and (max-width: 960px) {
  .swiper-container-margin {
    margin-top: 0px;
  }
}

.swiper-container {
  width: 100%;
  max-height: 600px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  max-height: 600px;

  background-size: 100% 100%;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  max-height: 600px;
}

#root .banner .swiper-button-prev,
#root .banner .swiper-button-next {
  color: rgb(37, 44, 68) !important;
}

.swiper-button-prev {
  /* color: rgb(209, 209, 209) !important; */
  color: rgb(37, 44, 68);
}

.swiper-button-next {
  /* color: rgb(209, 209, 209) !important; */
  color: rgb(37, 44, 68);
}
/*  */
.contenedor {
  position: relative;
  display: inline-block;
}

.texto-encima {
  color: white;
  background: rgba(45, 80, 200);
  background: linear-gradient(
    90deg,
    rgba(45, 80, 200, 0) 0%,
    rgba(45, 80, 200, 0.3) 4%,
    rgba(45, 80, 200, 0.6) 50%,
    rgba(45, 80, 200, 0.3) 96%,
    rgba(45, 80, 200, 0)
  );
}

.paragraph {
  /* font-size: 1.4rem; */
  margin: 0;
}
.text_paragraph {
  margin-bottom: 0;
}

.cardBorder {
  width: 90%;
  height: 300px;
}

.card_style {
  box-shadow: 10px 10px 25px rgba(180, 180, 150, 0.5);
  border-radius: 10px;
  background-color: white;
  height: auto;
}

.mySwiper {
  max-width: 1500px;
}

/* .carrousel-container {
  max-width: 1500px;
} */

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.buttonShadow {
  box-shadow: 0px 1px 1px 1px rgba(118, 118, 109, 0.6);
}

.imagenMuestra {
  /* -webkit-transform: skewX(-5deg);
  transform: skewX(-5deg); */
  box-shadow: 5px 5px 5px black;
  -webkit-transform: rotate3d(0, 10, 60, 5deg);
          transform: rotate3d(0, 10, 60, 5deg);
  border-radius: 10px;
}

.imgShadow {
  box-shadow: 5px 5px #888888;
}

.product-card {
  width: 25rem;
}

.logoFooter {
  width: 150px;
  height: 100px;
}
.backcolorFooter {
  background-color: #ebebeb;
}

.footer_link a {
  color: #898989;
  text-decoration: none;
  cursor: pointer;
}

.footer_link a:hover {
  color: #0a58ca !important;
}

.vinietas > li {
  color: rgb(150, 149, 149);
}

.vinietas > li > span {
  color: #898989;
  text-decoration: none;
}

.btn-flotante {
  font-size: 26px; /* Cambiar el tamaño de la tipografia */
  text-transform: uppercase; /* Texto en mayusculas */
  font-weight: bold; /* Fuente en negrita o bold */
  color: #ffffff; /* Color del texto */
  border-radius: 10px; /* Borde del boton */
  cursor: pointer;
  /* padding: 16px 23px; Relleno del boton */
  position: fixed;
  bottom: 40px;
  right: 10px;
  transition: all 300ms ease 0ms;
  z-index: 99;
  /* border-radius: 50px; */
  width: 5rem;
}

.btn-flotante:hover {
  color: #0d6efd;
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}

@media (min-width: 992px) {
  .btn-flotante {
    bottom: 40px;
    right: 40px;
  }
}

@media (min-width: 1200px) {
  .btn-flotante {
    font-size: 14px;
    padding: 12px 20px;
    bottom: 20px;
    width: 20rem;
  }
}

.linksFooter {
  color: #898989;
  text-decoration: none;
}

@media (min-width: 768px) {
  .five-cols .col-md-2,
  .five-cols .col-sm-2,
  .five-cols .col-lg-2 {
    width: 20%;
    *width: 20%;
  }
}

@media (min-width: 992px) {
  .five-cols .col-md-2,
  .five-cols .col-sm-2,
  .five-cols .col-lg-2 {
    width: 20%;
    *width: 20%;
  }
}

@media (min-width: 1200px) {
  .five-cols .col-md-2,
  .five-cols .col-sm-2,
  .five-cols .col-lg-2 {
    width: 20%;
    *width: 20%;
  }
}

.five-cols .col-md-2,
.five-cols .col-sm-2,
.five-cols .col-lg-2 {
  width: 20%;
  *width: 20%;
}

.fontbold {
  font-weight: bold;
}

.fachadaLista li {
  padding: 15px 0;
}

.listaMuroMovil li {
  margin: 25px 0;
}

.listaMuroMovil {
  margin-top: auto;
  margin-bottom: auto;
}

.textoMuroMovil {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .muroImagenCentrada {
    display: block;
    margin: 0 auto;
  }
}

.seven-cols .col-md-1,
.seven-cols .col-sm-1,
.seven-cols .col-lg-1 {
  width: 33.3%;
  *width: 33.3%;
}

@media (min-width: 768px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 25%;
    *width: 25%;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
  }
}

@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
  }
}

.imgTamanio {
  width: 160px;
  border-radius: 15px;
  height: 130px;
  box-shadow: 2px 2px 2px 2px rgba(165, 160, 160, 0.5);
}

.listado {
  list-style: none;
  padding-left: 0;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 2rem;
}

.listado li {
  padding: 10px 0;
}

.listado li:hover {
  background-color: #0060ee;
  border-radius: 10px;
}

.imagenListado {
  width: 40%;
}

.listado .active {
  background-color: #0060ee;
  border-radius: 10px;
}

.imagenFinal {
  object-fit: cover;
}

.marginListaPuertas li {
  margin-top: 15px;
}

